import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

$(document).ready(() => {
    var elements = $('.swiper-featured-product');
    $(elements).each(function( index ) {
        initSwiperFeaturedProduct($(this));
    });
});

function initSwiperFeaturedProduct(elem)
{
    var id = elem.attr('id');
    if (id == '') {
        console.log('initSwiperFeaturedProduct : aucun id de défini');
        return;
    }
    var speed = 300;
    var repeat = false;
    var arrows_desktop = true;
    var arrows_mobile = true;
    var dots_desktop = true;
    var dots_mobile = true;

    const config_swiper = {
        slidesPerView: 2,
        spaceBetween: 15,
        loop: repeat,
        speed: speed,
        centeredSlidesBounds: true,
        grid: {
            rows: 1,
        },
        breakpoints: {
            992: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        },
    };

    const modules = [];

    //Pagination
    if (dots_desktop || dots_mobile) {
        modules.push(Pagination);
        config_swiper.pagination = {
            el: "."+id+"-swiper-pagination",
            clickable: true
        };
    }

    //Navigation
    if (arrows_desktop || arrows_mobile) {
        modules.push(Navigation);
        config_swiper.navigation = {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        };
    }


    //Modules
    config_swiper.modules = modules;

    //Init Swiper
    var swiper = new Swiper("#"+id, config_swiper);
}